import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {searchTerm:"", searchCriteria:""};

export const searchSlice = createSlice({
	name: "search",
	initialState: { value: initialStateValue},
	reducers: {
		setSearchState: (state, action) => {
			state.value = action.payload;
		},
		clearSearchState: (state, action) => {
			state.value = initialStateValue;
		}
	}
});

export const { setSearchState, clearSearchState } = searchSlice.actions;

export default searchSlice.reducer;